import React from "react"
import Layout from "../components/layout/layout"

function About(props) {
  return (
    <Layout>

    </Layout>
  )
}

export default About
