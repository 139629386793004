export const socialLinks = {
  facebook: 'https://www.facebook.com/jack.sari.37/',
  twitter: 'https://twitter.com/ssari1212',
  linkedin: 'https://www.linkedin.com/in/jacksari/',
  website: 'https://www.jacksari.com/',
  instagram: 'https://www.instagram.com/jana123df/',
  github: 'https://github.com/jacksari',
  correo: 'mailto:janasarii@gmail.com'
};

